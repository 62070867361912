import abeagle from './abeagle';

const testSlug = 'hp_games';

if (
  window.HP.params.edition === 'us'
  && window.HP.params.isGame
  && window.HP.params.abeagle
  && Array.isArray(window.HP.params.abeagle)
  && window.HP.params.abeagle.includes(testSlug)
) {
  document.addEventListener('abeagleEvent', () => {
    let variant = abeagle.getExperimentVariant(testSlug);
    const urlParams = new URLSearchParams(window.location.search);
    const gamesVariation = urlParams.get('game_version');
    // this is only to fire the event fo hp_games_test when user selects the variant
    if (gamesVariation) {
      variant = gamesVariation !== 'arkadium' ? 'control' : 'arkadium';
    }
    window.HP.params.abGamesTest.variant = variant;
    document.body.classList.add(`ab-games-${variant}`);
    if (variant === 'arkadium') {
      const gamesElement = document.querySelector('.game__content--daily-crossword-arkadium');
      if (gamesElement) {
        gamesElement.classList.remove('hidden');
      }
      const betaLinkElement = document.getElementById('test-beta-link');
      if (betaLinkElement) {
        const abTestLink = betaLinkElement.getAttribute('data-abTest-link');
        if (abTestLink) {
          betaLinkElement.setAttribute('href', abTestLink);
          betaLinkElement.setAttribute('data-vars-target-content-id', abTestLink);
        }
      }
    } else {
      const gamesElement = document.querySelector('.game__content--daily-crossword');
      if (gamesElement) {
        gamesElement.classList.remove('hidden');
      }
    }
  }, { once: true });
  window.HP.params.abGamesTest.ready = true;
}
