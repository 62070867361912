/* eslint-disable camelcase */

if (window.HP.params.isFrontFlex) {
  const allCards = document.querySelectorAll('.front-page-content .js-can-badge');
  const timestampClass = 'js-timestamp';
  const newClass = 'js-new'; // new is not a type of badge and only shows on badgeless cards
  const trendingClass = 'js-trending';
  const zoneTrendingClass = 'js-has-trending-card';
  const oneHour = 60 * 60 * 1000;
  const { trendingEntryIDs } = window.HP.params;
  const counter = {
    trending: 0,
  };

  allCards.forEach((card) => {
    const { liveblogEndTime, timestamp, varsItemName: entryId } = card.dataset;
    let badge;
    try {
      badge = JSON.parse(card.dataset.badge);
    } catch (e) {
      badge = {};
    }
    const { start_time, end_time, value } = badge;
    const hasBadgeData = !!value;
    const zoneParent = card.closest('.zone');

    const parentDiv = card.querySelector('.card__text__top');

    const now = Date.now();
    let start;
    let end;
    if (start_time) {
      start = new Date(start_time).getTime();
    }
    if (end_time) {
      end = new Date(end_time).getTime();
    }

    const isLive = now >= start && (!end || now < end);
    const showBadge = hasBadgeData && isLive;
    let hasBadge = false;
    // manual badge check
    if (showBadge) {
      hasBadge = true;
      const badgeDiv = document.createElement('div');
      badgeDiv.className = `card__badge card__badge--${value}`;
      let text = value.replace('-', ' ').toUpperCase();
      if (value === 'last-chance') {
        text = `${text}!`;
      }
      badgeDiv.innerHTML = text;
      parentDiv.classList.add('card__text__top--has-badge');
      parentDiv.appendChild(badgeDiv);
    }

    // liveblog check
    if (liveblogEndTime > now && !hasBadge) {
      hasBadge = true;
      const liveblogDiv = document.createElement('div');
      liveblogDiv.className = 'card__badge card__badge--liveblog';
      liveblogDiv.innerHTML = 'LIVE';
      parentDiv.classList.add('card__text__top--has-badge');
      parentDiv.appendChild(liveblogDiv);
    }

    // timestamp check -- must happen after other badge checks
    const msAgo = now - timestamp;
    if (msAgo < oneHour) {
      // if no badge is present add NEW stamp
      if (!hasBadge) {
        card.classList.add(newClass);
        hasBadge = true;
        const newDiv = document.createElement('div');
        newDiv.className = 'card__new';
        newDiv.innerHTML = 'NEW';
        parentDiv.appendChild(newDiv);
      }

      // add timestamp to any "new" cards
      const minutesAgo = Math.floor(msAgo / 60000);
      card.classList.add(timestampClass);
      const timestampDiv = document.createElement('div');
      timestampDiv.className = 'card__timestamp';
      timestampDiv.innerHTML = minutesAgo < 1 ? 'a few sec ago' : `${minutesAgo} min ago`;
      parentDiv.classList.add('card__text__top--has-badge');
      parentDiv.appendChild(timestampDiv);
    }

    // trending check -- lowest priority
    if (
      !hasBadge
      && counter.trending < 2
      && !zoneParent.classList.contains(zoneTrendingClass)
      && trendingEntryIDs.includes(entryId)
    ) {
      hasBadge = true;
      card.classList.add(trendingClass);
      zoneParent.classList.add(zoneTrendingClass);
      counter.trending += 1;
      const trendingDiv = document.createElement('div');
      trendingDiv.className = 'card__badge card__badge--trending';
      trendingDiv.innerHTML = 'TRENDING';
      parentDiv.classList.add('card__text__top--has-badge');
      parentDiv.appendChild(trendingDiv);
    }
  });
}
